import { t } from '@/utility/localization';
import { sendOtpToEmail } from '@/services/userService';
import { useCallback, useEffect, useState } from 'react';
import OtpInTextField from '@/components/common/OtpInTextField';
import RoundedButton from '@/components/common/RoundedButton';
import { showErrorToast } from '@/components/common/ToastContainer';
import { useAuthContext } from '@/contexts/AuthContext';
import { LOGIN_FORM_STATES } from './constants';
import { trackGAEvent } from '@/utility/analytics';
import { MAX_OTP_LENGTH } from '@/utility/otpUtils';

const OtpForm = ({
  setFormState,
  setFormValues,
  formValues,
  postLogin
}) => {
  const { email } = formValues;
  const { loginWithOtpValidation } = useAuthContext();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const updateFormValue = useCallback(
    (fieldName, fieldValue) => {
      setFormValues((prev) => ({
        ...prev,
        [fieldName]: fieldValue
      }));
    },
    [setFormValues]
  );

  const handleSendOtpToEmail = async (isInitAutoTrigger = false) => {
    if (!email) {
      return;
    }
    updateFormValue('otpError', ''); //reset the error message
    const { data, error } = await sendOtpToEmail(email);

    if (!error) {
      updateFormValue('otpToken', data.otpToken);
    } else {
      if (!isInitAutoTrigger) updateFormValue('otpError', error);
      // why do we need to check isInitAutoTrigger ? initial trigger might be due to component unmounting and remounting. leading to error from BE that last token was recently created. In this error, we don't want to notify the user.
    }
  };

  const onSubmit = async (e) => {
    e?.preventDefault();
    setIsSubmitting(true);

    //this case happens when sendOtpToEmail fails
    //need to request a new OTP with "Resend"
    if (!formValues.otpToken) {
      setIsSubmitting(false);

      updateFormValue(
        'otpError',
        t('your-otp-has-expired-click-on-resend-to-get-a-new-one')
      );

      return;
    }

    const response = await loginWithOtpValidation({
      otpToken: formValues.otpToken,
      email,
      otp: formValues.otpCode
    });

    const { error, data } = response;

    if (error) {
      showErrorToast(error);
      updateFormValue('otpError', error);

      // Only stop loading state if have error
      // else let it continue until user gets routed out
      setIsSubmitting(false);
    } else {
      updateFormValue('passwordToken', data.passwordToken);

      await postLogin?.(response?.data?.user);

      trackGAEvent('login_with_otp_success');
    }
  };

  const handleOnChangeOtp = (value) => {
    setFormValues((prev) => ({
      ...prev,
      otpCode: value,
      otpError: ''
    }));
  };

  useEffect(() => {
    if (formValues?.otpCode?.length === MAX_OTP_LENGTH) {
      onSubmit();
    }
  }, [formValues?.otpCode]);

  useEffect(() => {
    //reset the otp and otpToken when the component is loaded
    setFormValues((prev) => ({
      ...prev,
      otpCode: '',
      otpToken: ''
    }));
    handleSendOtpToEmail(true);
    //only call this once when page loads
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="c-OtpForm">
      <form className="w-full" onSubmit={onSubmit}>
        <div className="mb-24 w-full space-y-24">
          <div>
            <p className="block w-full text-body-sm text-npl-text-icon-on-light-surface-secondary">
              {t('please-enter-the-verification')}
            </p>
            <p className="block w-full text-body-sm font-medium text-npl-text-icon-on-light-surface-secondary">
              {email}
            </p>
          </div>
          <OtpInTextField
            type="number"
            name="otpCode"
            placeholder={t('verification-code')}
            label={t('verification-code')}
            focus
            onChangeValue={handleOnChangeOtp}
            onResend={handleSendOtpToEmail}
            // error={otpError}
            error={formValues?.otpError}
          />
        </div>

        <div className="space-y-8">
          <RoundedButton
            type="submit"
            displayType="primary"
            customClassNames="w-full flex justify-center h-[48px]"
            isLoading={isSubmitting}
            data-test-id="otp-submit-button"
            disabled={!formValues.otpCode}>
            {t('continue')}
          </RoundedButton>
          {formValues?.isActiveUser && (
            <RoundedButton
              displayType="plain"
              customClassNames="w-full flex justify-center h-[48px] text-npl-text-icon-on-light-surface-secondary"
              data-test-id="enter-password"
              onClick={() => {
                setFormState({
                  current: LOGIN_FORM_STATES.PASSWORD_FORM,
                  previous: LOGIN_FORM_STATES.OTP_FORM
                });

                trackGAEvent('login_enter_password_btn_clicked');
              }}>
              {t('enter-password')}
            </RoundedButton>
          )}
          <RoundedButton
            displayType="plain"
            customClassNames="w-full flex justify-center h-[48px] text-npl-text-icon-on-light-surface-secondary"
            data-test-id="back-button"
            onClick={() => {
              setFormState((prev) => ({
                current: prev.previous
              }));
            }}>
            {t('back')}
          </RoundedButton>
        </div>
      </form>
    </div>
  );
};

export default OtpForm;
